export function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error)
            return
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

export const numDifferentiation = (val) => {
  if (val === undefined || val === null) return null
  if (val >= 10000000) val = parseFloat((val / 10000000).toFixed(2)) + ' Cr'
  else if (val >= 100000) val = parseFloat((val / 100000).toFixed(2)) + ' Lac'
  else if (val >= 1000) val = parseFloat((val / 1000).toFixed(2)) + ' K'
  return val < 10 ? '0' + val.toString() : val.toString()
}
